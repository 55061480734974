
body,
html {
  margin: 0;
  display: flex;

  flex: 1;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-color: rgb(65, 13, 91) white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#root {
  display: flex;
  min-width: 100vw;
  flex: 1;
}
.preload * {
  animation-duration: 0s !important;
  animation-delay: 0s !important;
}

