.button {
  transition-duration: 0s;
}
.button::after {
  content: "";
  background-color: rgba(239, 239, 240, 0.4);
  width: 0;
  position: absolute;
  height: 0;
  border-radius: 10px;
  transition: all 0.4s;
}
.button:active:after {
  width: 100%;
  height: 100%;
  transition: 0s;
}
