#container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.cadastro {
  flex: 1;
  background-color: #787;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
}

form {
  display: flex;
  flex-direction: row;
}

form .inputs {
  display: flex;
  flex-direction: column;
  padding: 10px;
  font-weight: bolder;
  /* background-color: #877; */
}

form .inputs input,
form .inputs select {
  border: none;
  outline: none;
}

form button {
  background-color: #454;
  color: #fff;
  border: none;
  margin: 5px;
}

form button:hover {
  background-color: #343;
}

form button:active {
  background-color: #232;
}

.map {
  flex: 1;
  height: 80%;
  width: 65%;
}

.marker-text {
  width: 100%;
  height: 100%;
  text-align: center;
  font-weight: 600;
  background-color: #444;
  color: #eee;
  border-radius: 0.5rem;
}

.map-marker {
  width: 30px;
  transform: translateX(-50%) translateY(-25%);
}
