.toast-botton {
  animation: fadein 0.5, fadeout 0.5s 2.5s;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
}
.toast-top-right-fadein{
  animation: fadeinright 0.5s;
  -webkit-animation: fadeinright 0.5s;
}
.toast-top-right-fadeout{
  animation: fadeoutright;
  animation-duration:1s;
  -webkit-animation: fadeoutright 0.5s;
}

.toast-panic{
  animation: fadeinpanic 0.5, fadeoutpanic 0.5s 9.5s;
  -webkit-animation: fadeinpanic 0.5s, fadeoutpanic 0.5s 9.5s;
}



@keyframes fadeinright {
  from {
    opacity:0;
    right: -1000000px;
  }
  to {
      opacity:1;
    right: 0px;
  }
}

@-webkit-keyframes fadeinright {
  from {
    right: -100%;
    opacity:0;

  }
  to {
    right: 3%;
    opacity:1;

  }
}


@keyframes fadeoutright {
  from {
    right: 3%;
    opacity: 1;
  }
  to {
    right: -100%;
    opacity: 0;
  }
}
@-webkit-keyframes fadeoutright {
  from {
    right: 3%;
    opacity: 1;
  }
  to {
    right: -100%;
    opacity: 0;
  }
}

@keyframes fadeinpanic {
  from {
    right: 0%;
    opacity: 0;
  }
  to {
    right: 1%;
    opacity: 1;
  }
}
@-webkit-keyframes fadeinpanic {
  from {
    right: 0%;
    opacity: 0;
  }
  to {
    right: 1%;
    opacity: 1;
  }
}

@keyframes fadeoutpanic {
  from {
    right: 1%;
    opacity: 1;
  }
  to {
    right: 0%;
    opacity: 0;
  }
}
@-webkit-keyframes fadeoutpanic {
  from {
    right: 1%;
    opacity: 1;
  }
  to {
    right: 0%;
    opacity: 0;
  }
}


@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 3%;
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 3%;
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    bottom: 3%;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
@-webkit-keyframes fadeout {
  from {
    bottom: 3%;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
