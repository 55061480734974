.component-spin{
    border-radius: 50%;
    border: 6px solid #f4f4f4;
    border-top:6px solid rgb(65, 13, 91);
    animation: loading-spin 2s linear infinite;
    -webkit-animation: loading-spin 1s linear infinite; /* Safari */
}

@keyframes loading-spin {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}

@-webkit-keyframes loading-spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
